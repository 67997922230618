.modal-centred-buttons .ant-modal-confirm-btns {
    display: flex;
    justify-content: center;
    float: none;
}

.modal-centred-buttons .ant-modal-error-btns {
    display: flex;
    justify-content: center;
    float: none;
}
