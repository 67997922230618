@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html,
    body {
        padding: 0;
        margin: 0;
        background-color: #fafafa;
    }

    html,
    body,
    input,
    textarea,
    button {
        font-family:
            -apple-system,
            Lato,
            BlinkMacSystemFont,
            Segoe UI,
            Roboto,
            Oxygen,
            Ubuntu,
            Cantarell,
            Fira Sans,
            Droid Sans,
            Helvetica Neue,
            sans-serif;
        line-height: 1.5;
        font-size: 16px;
    }

    * {
        box-sizing: border-box;
    }

    a {
        color: #0070f3;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }

    img {
        max-width: 100%;
        display: block;
    }

    ul,
    ol {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    dd,
    dt,
    dl {
        margin: 0;
    }

    .all-unset {
        all: unset;
    }

    fieldset {
        border: none;
    }
}

@layer components {
    .label-required:after {
        content: " *";
        color: #eb001b;
    }
    .typography-h1 {
        font-size: 2rem;
        font-weight: 500;
        line-height: 2.25rem;
    }
    .typography-h2 {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2rem;
    }
    .typography-h3 {
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.75rem;
    }
    .typography-h4 {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.375rem;
    }
    .typography-h5 {
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.25rem;
    }
    .typography-h6 {
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 1rem;
    }
    .typography-main {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
    }
    .typography-sub {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
    }
    .typography-tiny {
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1rem;
    }
    .typography-micro {
        font-size: 0.5625rem;
        font-weight: 400;
        line-height: 0.875rem;
    }
    .typography-overline-lg {
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.25rem;
        text-transform: uppercase;
        line-height: 1.25rem;
        letter-spacing: 1px;
    }
    .typography-overline {
        font-size: 0.75rem;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 1.25rem;
        letter-spacing: 1px;
    }
    .typography-display-sm {
        font-size: 2.5rem;
        line-height: 3rem;
    }
    .typography-display-lg {
        font-size: 3.25rem;
        line-height: 3.5rem;
    }
    .blur-edges {
        background: linear-gradient(
            89.92deg,
            rgba(255, 255, 255, 0) 0.09%,
            #ffffff 103.92%
        );
    }
    .link-plain {
        @apply text-neutral-900 no-underline hover:text-neutral-900 hover:no-underline active:text-neutral-900 active:no-underline;
    }
    .no-arrow::marker {
        content: "";
    }
    .no-arrow::-webkit-details-marker {
        display: none;
    }
}
@layer utilities {
    .p-safe-inset-t {
        padding-top: env(safe-area-inset-top);
    }
    .p-safe-inset-b {
        padding-bottom: env(safe-area-inset-bottom);
    }
    a:hover.remove-styles-a {
        text-decoration: none;
    }
}
